import { shaderMaterial } from '@react-three/drei'
import { extend } from '@react-three/fiber'
import { Color, Vector2 } from 'three'
import fragmentShader from './background.frag'
import vertexShader from './background.vert'

const BackgroundMaterial = shaderMaterial(
  {
    colorTop: new Color(),
    colorBottom: new Color(),
    resolution: new Vector2()
  },
  vertexShader,
  fragmentShader
)

extend({ BackgroundMaterial })

export { BackgroundMaterial }
