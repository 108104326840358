import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import Icon from '@/components/core/Icon/Icon'
import classNames from '@/lib/util/classNames'
import styles from './NavLink.module.scss'

/**
 * The `NavLink` component
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
export default function NavLink (props) {
  const {
    activeStyle = 'highlight',
    children,
    external,
    href,
    icon,
    invert = false,
    ...rest
  } = props
  const ref = useRef()
  const { route } = useRootContext()
  const [isHover, setIsHover] = useState(false)
  const [isHoverIn, setIsHoverIn] = useState(false)
  const [isHoverOut, setIsHoverOut] = useState(false)
  const lastHover = useRef(false)
  const isTimerRunning = useRef(false)
  const didHoverIn = useRef(false)
  const timer = useRef()
  const Tag = useMemo(() => (external ? 'a' : Link), [external])
  const linkConfig = useMemo(
    () => (!external ? { to: href } : { href }),
    [external, href]
  )
  const isActiveLink = useMemo(() => !!route?.path?.exec(href), [route])

  const classNameOutput = useMemo(
    () =>
      classNames(
        styles.container,
        isActiveLink && styles.active,
        ((activeStyle === 'highlight' && isActiveLink) || isHoverIn) &&
          styles.in,
        isHoverOut && styles.out,
        icon && styles.hasIcon,
        invert && styles.invert,
        styles[`active-style-${activeStyle}`]
      ),
    [isActiveLink, isHoverOut, isHoverIn, icon, invert, activeStyle]
  )

  useEffect(() => {
    if (lastHover.current === isHover) {
      return
    }

    lastHover.current = isHover

    if (isHover) {
      setIsHoverOut(false)

      timer.current = setTimeout(() => {
        setIsHoverIn(true)
        isTimerRunning.current = false
        didHoverIn.current = true
      }, 100)

      isTimerRunning.current = true
    } else {
      if (didHoverIn.current) {
        setIsHoverIn(false)
        setIsHoverOut(true)

        timer.current = setTimeout(() => {
          setIsHoverOut(false)
          isTimerRunning.current = false
        }, 300)

        isTimerRunning.current = true
        didHoverIn.current = false
      }
    }

    return () => clearTimeout(timer.current)
  }, [isHover])

  const handlePointerEnter = useCallback(() => {
    setIsHover(true)
  }, [])

  const handlePointerLeave = useCallback(() => {
    setIsHover(false)
  }, [])

  useEffect(() => {
    lastHover.current = false
  }, [])

  return (
    <Tag
      className={classNameOutput}
      ref={ref}
      {...linkConfig}
      {...rest}
      onMouseEnter={handlePointerEnter}
      onMouseLeave={handlePointerLeave}
    >
      {icon && (
        <div className={styles.icon}>
          <Icon icon={icon} />
        </div>
      )}
      <div className={styles.content}>
        <span className={styles.label}>{children}</span>
        <span aria-hidden="true" className={styles.bar} />
      </div>
    </Tag>
  )
}

NavLink.propTypes = {
  activeStyle: PropTypes.string,
  Tag: PropTypes.elementType,
  children: PropTypes.node,
  icon: PropTypes.string,
  external: PropTypes.bool,
  href: PropTypes.string,
  invert: PropTypes.bool
}
