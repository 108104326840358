import { ScreenQuad } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import { useMemo } from 'react'
import { BackgroundMaterial } from './BackgroundMaterial/BackgroundMaterial'

/**
 * The `BackgroundScene`
 * @returns {React.ReactElement} the element
 */
export default function BackgroundScene () {
  const { size } = useThree()
  const resolution = useMemo(
    () => [size.width, size.height],
    [size.width, size.height]
  )

  return (
    <ScreenQuad scale={100} depthWrite={false} position={[0, 0, -10]}>
      <backgroundMaterial
        key={BackgroundMaterial.key}
        depthWrite={false}
        colorTop={'#5f767b'}
        colorBottom={'#000'}
        resolution={resolution}
      />
    </ScreenQuad>
  )
}
