import { Suspense, lazy, memo } from 'react'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import Loader from '@/components/interface/Loader/Loader'

const Home = lazy(
  () => import(/* webpackChunkName: "route-home" */ '@/pages/Home/Home')
)
const BecomeACitizen = lazy(
  () =>
    import(
      /* webpackChunkName: "route-become-a-citizen" */ '@/pages/BecomeACitizen/BecomeACitizen'
    )
)
const Partners = lazy(
  () =>
    import(
      /* webpackChunkName: "route-partners" */ '@/pages/Partners/Partners'
    )
)

/**
 *
 * The `Routes` container component manages the routing for the application.
 * @param {object} props - the component props
 * @returns {ReactElement} the element
 */
function Routes (props) {
  const { route } = useRootContext()

  return (
    <Suspense fallback={<Loader.Suspense />}>
      {route.type === 'home' && <Home />}
      {route.type === 'become-a-citizen' && <BecomeACitizen />}
      {route.type === 'partners' && <Partners />}
    </Suspense>
  )
}

export default memo(Routes)
