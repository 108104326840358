import { Canvas, extend } from '@react-three/fiber'
import { geometry } from 'maath'
import PropTypes from 'prop-types'
import { createContext, memo, useContext, useMemo } from 'react'
import tunnel from 'tunnel-rat'
import Intersection from '@/lib/react/Intersection/Intersection'
import { isSafari, isTouch } from '@/lib/util/support'
import BackgroundScene from '../BackgroundScene/BackgroundScene'
import styles from './CanvasContainer.module.scss'

extend({ RoundedPlaneGeometry: geometry.RoundedPlaneGeometry })

export const context = createContext({})

/**
 * The `useCanvasContainer` hook
 * @returns {object} the context object
 */
export function useCanvasContainer () {
  return useContext(context)
}

/**
 * The `CanvasWrapper`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function CanvasWrapper (props) {
  const { children } = props
  const contentTunnel = tunnel()
  const canvasTunnel = tunnel()

  const ctx = useMemo(
    () => ({
      contentTunnel,
      canvasTunnel
    }),
    [contentTunnel, canvasTunnel]
  )

  return (
    <context.Provider value={ctx}>
      <Intersection.Context>
        <div className={styles.container}>
          <contentTunnel.Out />

          <Canvas
            dpr={[1.5, isTouch ? 1.5 : isSafari ? 1.5 : 2]}
            camera={{ position: [0, 0, 20], fov: 15 }}
          >
            <BackgroundScene />
            <canvasTunnel.Out />
          </Canvas>
        </div>
        {children}
      </Intersection.Context>
    </context.Provider>
  )
}

CanvasWrapper.propTypes = {
  children: PropTypes.node
}

export default memo(CanvasWrapper)
