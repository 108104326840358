import { useEffect, useMemo, useState } from 'react'

/**
 * The `useInterface` hook
 * @param {object} appData - the application data
 * @param {object} route - the current route info
 * @param {boolean} isRouteTransitioning - whether the router is transitioning
 * @returns {object} the context object
 */
export default function useInterface (appData, route, isRouteTransitioning) {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false)

  /**
   * Effect: When transitioning the route, close the burger
   */
  useEffect(() => {
    if (!isRouteTransitioning) {
      return
    }

    setIsBurgerMenuOpen(false)
  }, [isRouteTransitioning])

  const ctx = useMemo(
    () => ({
      isBurgerMenuOpen,
      setIsBurgerMenuOpen
    }),
    [isBurgerMenuOpen, setIsBurgerMenuOpen]
  )

  return ctx
}
