import { useCallback, useEffect, useRef, useState } from 'react'
import { isTouch } from '@/lib/util/support'

/**
 * The `usePressEvents` hook is used to handle detection
 * of pressing of an element
 * @param {object} ref - the react ref
 * @param {boolean} useTouchDelayedRelease - use delayed release
 * @returns {boolean} whether is hovering
 */
export default function usePressEvents (ref, useTouchDelayedRelease = true) {
  const [isPressing, setIsPressing] = useState(false)
  const lastNode = useRef(null)
  const pressTimeout = useRef(null)

  /**
   * On press
   */
  const handlePress = useCallback(e => {
    if (e.button !== undefined && e.button !== 0) {
      return
    }

    clearTimeout(pressTimeout.current)
    setIsPressing(true)

    window.addEventListener('mouseup', handleRelease)
  }, [])

  /**
   * On release
   */
  const handleRelease = useCallback(() => {
    clearTimeout(pressTimeout.current)

    pressTimeout.current = setTimeout(
      () => {
        setIsPressing(false)
      },
      isTouch && !useTouchDelayedRelease ? 0 : 75
    )

    window.removeEventListener('mouseup', handleRelease)
  }, [])

  /**
   * Add the handlers
   */
  const addHandlers = useCallback(node => {
    if (!node) {
      return
    }

    if (!isTouch) {
      node.addEventListener('mousedown', handlePress)
    } else {
      node.addEventListener('touchstart', handlePress)
      node.addEventListener('touchend', handleRelease)
      node.addEventListener('touchcancel', handleRelease)
    }
  }, [])

  /**
   * Remove the handlers
   */
  const removeHandlers = useCallback(node => {
    if (!node) {
      return
    }

    node.removeEventListener('mousedown', handlePress)
    window.removeEventListener('mouseup', handleRelease)
    node.removeEventListener('touchstart', handlePress)
    node.removeEventListener('touchend', handleRelease)
    node.removeEventListener('touchcancel', handleRelease)
  }, [])

  /**
   * Effect: On mount/unmount
   */
  useEffect(() => {
    if (lastNode.current) {
      clearTimeout(pressTimeout.current)
      removeHandlers(lastNode.current)
      lastNode.current = null
    }

    const node = ref?.current

    if (!node) {
      return
    }

    lastNode.current = node

    addHandlers(node)

    return () => {
      clearTimeout(pressTimeout.current)
      removeHandlers(node)
    }
  }, [ref])

  return isPressing
}
