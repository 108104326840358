import { gsap } from 'gsap'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { dispatchPopStateEvent } from '@/lib/util/popstate'
import useBlockers from './useBlockers'
import configureRoutes from './util/configureRoutes'
import getDocumentTitle, {
  getCustomDocumentTitle
} from './util/getDocumentTitle'
import matchRoute from './util/matchRoute'

const DEFAULT_CONFIG = {
  routeTransitionDuration: 0.75
}

/**
 * The `useRoutes`
 * @param {object?} appData - the app data
 * @param {object?} config - the config
 * @returns {object} the context
 */
function useRoutes (appData, config = DEFAULT_CONFIG) {
  const { routeTransitionDuration } = config
  const location = useLocation()
  const [routes, setRoutes] = useState(null)
  const [nextRoute, setNextRoute] = useState(null)
  const [route, setRoute] = useState({
    type: null
  })
  const [isRouteTransitioning, setIsRouteTransitioning] = useState(false)
  const routeTransition = useRef({})
  const lastRoute = useRef({ type: null })
  const { addBlocker, blockers, removeBlocker } = useBlockers()
  const currentBlockers = useRef([])
  const lastLocation = useRef()
  const lastSwitch = useRef()

  /**
   * Validate the blockers
   */
  const validateBlockers = useCallback(async routeType => {
    const validate = currentBlockers.current[0]

    if (!validate) {
      return true
    }

    const canLeave = await validate()

    return canLeave
  }, [])

  /**
   * Validate before unload
   */
  const validateBlockersBeforeUnload = useCallback(e => {
    const validate = currentBlockers.current[0]

    if (!validate) {
      return true
    }

    const canLeave = validate(true)

    if (!canLeave) {
      e.preventDefault()
      e.returnValue = ''

      return false
    }

    return true
  }, [])

  /**
   * Closes the current route by resetting the `route` state,
   * then waits for the supplied duration before completing the
   * promise
   * @returns {Promise} resolves on completion
   */
  const closeRoute = useCallback(async () => {
    gsap.killTweensOf(routeTransition.current)

    return gsap.to(routeTransition.current, {
      duration: routeTransitionDuration
    })
  }, [])

  /**
   * Switch route
   */
  const switchRoute = useCallback(async () => {
    if (nextRoute.type !== lastRoute.current.type) {
      const canSwitch = await validateBlockers(nextRoute.type)

      if (!canSwitch) {
        window.history.replaceState(null, '', lastSwitch?.current?.pathname)
        dispatchPopStateEvent()

        return
      }

      lastSwitch.current = location
    }

    gsap.killTweensOf(routeTransition.current)

    if (nextRoute.type !== lastRoute.current.type && lastRoute.current.type) {
      // Is not the same route
      setIsRouteTransitioning(true)
      lastRoute.current = nextRoute
      await closeRoute()
    }

    window.scrollTo(0, 0)

    setIsRouteTransitioning(false)
    setRoute(nextRoute)

    lastRoute.current = nextRoute
  }, [nextRoute, validateBlockers, location])

  /**
   * Set the document title
   */
  const setDocumentTitle = useCallback(
    documentTitle => {
      document.title = getCustomDocumentTitle({
        appData,
        documentTitle
      })
    },
    [appData]
  )

  /**
   * Effect: When the `nextRoute` changes
   */
  useEffect(() => {
    if (!nextRoute) {
      return
    }

    switchRoute()
  }, [nextRoute])

  /**
   * Effect: When the location changes (and is ready)
   */
  useEffect(() => {
    if (!routes) {
      // Cancel when routes aren't ready yet
      return
    }

    if (location.pathname === lastLocation.current?.pathname) {
      return
    }

    lastLocation.current = location

    const match = matchRoute(location.pathname, routes)
    const canNavigate = true

    if (!match.type || !canNavigate) {
      const rootPath = '/'

      setTimeout(() => {
        window.history.replaceState(null, null, rootPath)
        dispatchPopStateEvent()
      }, 100)

      match.type = 'home'
    }

    setNextRoute(match)
  }, [routes, location])

  /**
   * Effect: When the `appData` has loaded
   */
  useEffect(() => {
    if (!appData) {
      // Defensive
      return
    }

    setRoutes(configureRoutes(appData.routes))
  }, [appData])

  /**
   * Effect: When the `blockers` changes, copy into a
   * ref
   */
  useEffect(() => {
    currentBlockers.current = blockers
  }, [blockers])

  /**
   * Effect: On mount/unmount
   */
  useEffect(() => {
    window.addEventListener('beforeunload', validateBlockersBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', validateBlockersBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (!route.type) {
      return
    }

    document.title = getDocumentTitle({
      appData,
      route,
      setDocumentTitle
    })
  }, [route])

  const ctx = useMemo(
    () => ({
      route,
      isRouteTransitioning,
      addBlocker,
      removeBlocker,
      setDocumentTitle
    }),
    [route, isRouteTransitioning]
  )

  return ctx
}

export default useRoutes
