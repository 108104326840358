const maxDelay = 0.5

/**
 * Calculate the delay
 * @param {number} index - the index
 * @param {number} len - the len
 * @param {number} strength - the strength
 * @returns {number} the delay
 */
export default function calculateDelay (index, len, strength = 1) {
  const delay = (index / len || 0) * maxDelay * (len <= 5 ? 0.5 : 1)

  return Number(delay.toFixed(2)) * strength
}
