import { useCallback, useMemo, useRef, useState } from 'react'

/**
 * The `useBlockers` add the capability
 * to register blockers that prevent route
 * changes from occurring while blocked
 * @returns {object} the context object
 */
function useBlockers () {
  const [blockers, setBlockers] = useState([])
  const currentBlockers = useRef([])

  /**
   * Add a blocker
   */
  const addBlocker = useCallback(callback => {
    currentBlockers.current = [...currentBlockers.current, callback]
    setBlockers(currentBlockers.current)
  }, [])

  /**
   * Remove a blocker
   */
  const removeBlocker = useCallback(callback => {
    currentBlockers.current = currentBlockers.current.filter(
      b => b !== callback
    )

    setBlockers(currentBlockers.current)
  }, [])

  /**
   * Memo: The context
   */
  const ctx = useMemo(
    () => ({
      blockers,
      addBlocker,
      removeBlocker
    }),
    [blockers]
  )

  return ctx
}

// Export
export default useBlockers
