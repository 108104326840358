import { matchPath } from 'react-router-dom'

/**
 * Matches a route and returns the route information, including any path params.
 * This method is used exclusively by the `Routes` system
 * @param {string} pathname - the location.pathname
 * @param {Array} routes - the routes array data
 * @returns {object?} the match result
 */
const matchRoute = (pathname, routes) => {
  const match = routes.find(({ path }) => path.exec(pathname))

  if (!match) {
    // Return null if match failed
    return {
      type: null
    }
  }

  const { params } = matchPath(match.originalPath, pathname) || { params: {} }

  return {
    ...match,
    params,
    pathname
  }
}

// Export
export default matchRoute
