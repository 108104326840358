import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import Button from '@/components/core/Button/Button'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import classNames from '@/lib/util/classNames'
import styles from './ButtonBurger.module.scss'

/**
 * The `ButtonBurger`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function ButtonBurger (props) {
  const { className } = props
  const { isBurgerMenuOpen, setIsBurgerMenuOpen } = useRootContext()
  const isActive = useDelayedActiveState(isBurgerMenuOpen, 0, 0.1)
  const classNameOutput = useMemo(
    () => classNames(className, styles.container, isActive && styles.active),
    [className, isActive]
  )

  return (
    <Button
      className={classNameOutput}
      onClick={() => setIsBurgerMenuOpen(last => !last)}
      active={isActive}
    >
      <span className={styles.label}>
        <span>Menu</span>
      </span>
      <div className={styles.dots} />
    </Button>
  )
}

/** @type {object} */
ButtonBurger.propTypes = {
  className: PropTypes.string
}

/** @type {object} */
ButtonBurger.defaultProps = {
  className: ''
}

// Memoize
export default memo(ButtonBurger)
