import { useProgress } from '@react-three/drei'
import { useEffect, useMemo } from 'react'
import { useLoader } from '../Provider/Provider'

let suspenseId = 0

/**
 * The `GLSuspense`
 * @returns {React.ReactElement} the element
 */
export default function GLSuspense () {
  const { addLoader, removeLoader, updateLoaderProgress } = useLoader()
  const { active, progress } = useProgress()
  const id = useMemo(() => suspenseId++, [])

  useEffect(() => {
    updateLoaderProgress(id, progress)
  }, [progress, updateLoaderProgress])

  useEffect(() => {
    if (active) {
      addLoader(id)
    } else {
      removeLoader(id)
    }

    return () => {
      removeLoader(id)
    }
  }, [active])

  return null
}
