import { shaderMaterial } from '@react-three/drei'
import { extend } from '@react-three/fiber'
import { Color, Vector2 } from 'three'
import fragmentShader from './background.frag'
import vertexShader from './background.vert'

const SceneBackgroundMaterial = shaderMaterial(
  {
    backgroundOpacity: 1,
    colorTop: new Color(),
    colorBottom: new Color(),
    colorShape: new Color(),
    resolution: new Vector2(),
    loadProgress: 0,
    size: 30,
    progress: 1,
    density: 1
  },
  vertexShader,
  fragmentShader
)

extend({ SceneBackgroundMaterial })

export { SceneBackgroundMaterial }
