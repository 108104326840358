import { useRef } from 'react'

/**
 * The `useFallbackRef` hook
 * @param {object} forwardedRef - the object props
 * @returns {Array} returns the iterable version of the props
 */
export default function useFallbackRef (forwardedRef) {
  const fallbackRef = useRef(null)
  const ref = forwardedRef || fallbackRef

  return ref
}
