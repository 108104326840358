import { useLayoutEffect, useMemo } from 'react'
import { useLoader } from '../Provider/Provider'

let suspenseId = 0

/**
 * The `Suspense`
 * @returns {React.ReactElement} the element
 */
export default function Supsense () {
  const { addLoader, removeLoader } = useLoader()

  const id = useMemo(() => suspenseId++, [])

  useLayoutEffect(() => {
    addLoader(id)

    return () => {
      removeLoader(id)
    }
  }, [])

  return null
}
