import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import usePressEvents from '@/lib/react/hooks/usePressEvents'
import classNames from '@/lib/util/classNames'
import { normalizeSizeString } from '@/lib/util/sizes'
import Icon from '../Icon/Icon'
import styles from './Button.module.scss'

/**
 * The `Button`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
const Button = forwardRef((props, forwardedRef) => {
  const {
    Tag = 'button',
    active,
    children,
    className,
    icon,
    secondary = false,
    size = 'standard',
    tertiary = false,
    ...rest
  } = props
  const ref = useFallbackRef(forwardedRef)
  const isPressing = usePressEvents(ref, false)
  const classNameOutput = useMemo(
    () =>
      classNames(
        className,
        styles.container,
        isPressing && styles.press,
        active && styles.active,
        styles[`size-${normalizeSizeString(size)}`],
        secondary && styles.secondary,
        tertiary && styles.tertiary,
        icon && styles.hasIcon
      ),
    [className, isPressing, active, size, icon, secondary, tertiary]
  )

  return (
    <Tag ref={ref} className={classNameOutput} {...rest}>
      {icon && (
        <>
          <Icon
            className={classNames(styles.icon, styles.before)}
            icon={icon}
          />
          <span className={styles.content}>{children}</span>
          <Icon className={classNames(styles.icon, styles.after)} icon={icon} />
        </>
      )}
      {!icon && children}
    </Tag>
  )
})

// Display name
Button.displayName = 'Button'

/** @type {object} */
Button.propTypes = {
  Tag: PropTypes.elementType,
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.elementType,
  size: PropTypes.string,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool
}

// Memoize
export default memo(Button)
