import PropTypes from 'prop-types'
import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo
} from 'react'
import { onPointer } from '@/app/thread'
import onResize from '@/lib/onResize'

export const PointerContext = createContext({
  state: {
    x: 0,
    y: 0
  }
})

export const usePointerContext = () => {
  const context = useContext(PointerContext)

  return context
}

/**
 * The `PointerProvider`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
export default function PointerProvider (props) {
  const { children } = props
  const state = useMemo(
    () => ({
      x: 0,
      y: 0,
      normalX: 0,
      normalY: 0,
      isOver: false
    }),
    []
  )

  useLayoutEffect(() => {
    let pageWidth = window.innerWidth
    let pageHeight = window.innerHeight
    let initialEnter = true

    const handleMove = () => {
      state.x = onPointer.state.x
      state.y = onPointer.state.y
      state.normalX = onPointer.state.x / pageWidth
      state.normalY = onPointer.state.y / pageHeight
    }

    const handleResize = () => {
      pageWidth = window.innerWidth
      pageHeight = window.innerHeight
      handleMove()
    }

    const handleEnter = () => {
      state.isOver = true

      if (initialEnter) {
        document.body.removeEventListener('mouseover', handleEnter)
        initialEnter = false
      }
    }

    const handleLeave = () => {
      state.isOver = false
    }

    handleResize()
    onPointer.onMove(handleMove)
    onPointer.onEnter(handleEnter)
    onPointer.onLeave(handleLeave)
    onResize.on(handleResize)

    document.body.addEventListener('mouseover', handleEnter)

    return () => {
      onPointer.offMove(handleMove)
      onPointer.offEnter(handleEnter)
      onPointer.offLeave(handleLeave)
      onResize.off(handleResize)
    }
  }, [])

  const ctx = useMemo(
    () => ({
      state
    }),
    []
  )

  return (
    <PointerContext.Provider value={ctx}>{children}</PointerContext.Provider>
  )
}

PointerProvider.propTypes = {
  children: PropTypes.node
}
