import pathToRegExp from 'path-to-regexp'

/**
 * Configures the routes into an array form
 * @param {object} routes - the input routes config
 * @returns {Array} the routes in array form
 */
const configureRoutes = routes =>
  Object.keys(routes).map(key => {
    const route = routes[key]

    return {
      key,
      type: route.type || 'page',
      path: pathToRegExp(route.path),
      originalPath: route.path
    }
  })

// Export
export default configureRoutes
