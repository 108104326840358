import debounce from '@/lib/util/debounce'

/**
 * The `DebounceBatch` util handles
 * a single batch of callbacks for
 * a given `waitTime`
 */
export default class DebounceBatch {
  /**
   * @class
   * @param {object} props - the config props
   * @param {number} props.waitTime - the wait time (in ms)
   * @param {Function} props.callback - the initial callback to add
   */
  constructor ({ callback, waitTime }) {
    /** @type {Set} */
    this.callbacks = new Set()

    // Add the initial callback
    this.callbacks.add(callback)

    /** @type {number} */
    this.requestID = null

    /** @type {Function} */
    this.execute = waitTime
      ? debounce(this.executeRAF, waitTime)
      : this.executeCallbacks
  }

  /**
   * Adds a callback to the batch
   * @param {Function} callback - the callback to add
   * @public
   */
  add (callback) {
    this.callbacks.add(callback)
  }

  /**
   * Removes a callback from the batch, and cancels
   * the debounce (if exists) if the batch is empty
   * @param {Function} callback - the callback to remove
   * @returns {number} the new size
   * @public
   */
  remove (callback) {
    this.callbacks.delete(callback)

    const size = this.callbacks.size

    if (!size && this.execute.cancel) {
      // Batch is empty, cancel any debounce
      this.execute.cancel()
      cancelAnimationFrame(this.requestID)
    }

    return size
  }

  /**
   * Execute callback
   * @param {Function} callback - the callback to execute
   */
  executeCallback = callback => {
    callback()
  }

  /**
   * Execute the callbacks
   * @private
   */
  executeCallbacks = () => {
    this.callbacks.forEach(this.executeCallback)
  }

  /**
   * Execute with RAF (used with debounce action)
   * @private
   */
  executeRAF = () => {
    cancelAnimationFrame(this.requestID)

    this.requestID = requestAnimationFrame(this.executeCallbacks)
  }
}
