import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'

const useDelayedActiveState = (
  isActive,
  delayActive = 0.01,
  delayInactive = 0,
  matchInitial = false
) => {
  const wasActive = useRef(matchInitial ? isActive : false)
  const timeout = useRef()
  const [isDelayedActive, setIsDelayedActive] = useState(
    matchInitial ? isActive : false
  )
  const isMounted = useRef()

  /**
   * Run the trigger
   */
  const trigger = useCallback((isActive, delay) => {
    if (!delay) {
      // No delay, set immediately
      setIsDelayedActive(isActive)

      return
    }

    timeout.current = setTimeout(() => {
      if (!isMounted.current) {
        // Defensive
        return
      }

      setIsDelayedActive(isActive)
    }, delay * 1000)
  }, [])

  /**
   * Effect: On mount/unmount
   */
  useLayoutEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  /**
   * Effect: When the `isActive` state changes
   */
  useEffect(() => {
    if (isActive === wasActive.current) {
      return
    }

    const lastActive = wasActive.current

    wasActive.current = isActive
    clearTimeout(timeout.current)

    if (isActive) {
      if (!lastActive && delayActive > 0) {
        // When was previously not active switching to active
        // re-set to not active (to account for delay when re-activating)
        trigger(false, 0)
      }

      trigger(isActive, delayActive)

      return
    }

    trigger(isActive, delayInactive)
  }, [isActive, delayActive, delayInactive, trigger])

  return isDelayedActive
}

// Export
export default useDelayedActiveState
