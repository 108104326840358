import { ScreenQuad } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { BackSide } from 'three'
import { SceneBackgroundMaterial } from './SceneBackgroundMaterial/SceneBackgroundMaterial'
import ShapeGeometry from './ShapeGeometry'
/**
 * The `Scene`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
export default function Scene (props) {
  const { isActive = false, isWorker = false } = props
  const ref = useRef()
  const state = useThree()
  const resolution = useMemo(
    () => [state.size.width, state.size.height],
    [state.size.width, state.size.height]
  )
  const { size, viewport } = useThree()
  const { invalidate } = useThree()
  const [isShown, setIsShown] = useState(true)
  const density = useMemo(() => state.gl.getPixelRatio(), [state])
  const wasActive = useRef(isActive)
  const meshRef = useRef()
  const scaleRef = useRef()

  useEffect(() => {
    if (isWorker) {
      const bc = new BroadcastChannel('loader_channel')

      bc.onmessage = event => {
        const { type, value } = event.data

        if (type === 'action') {
          setIsShown(value)
        }
      }

      self.postMessage({ type: 'startLoad' })
    }
  }, [])

  useEffect(() => {
    if (isActive === wasActive.current) {
      return
    }

    wasActive.current = isActive

    setIsShown(isActive)
  }, [isActive])

  useEffect(() => {
    gsap.killTweensOf(ref.current)
    gsap.killTweensOf(scaleRef.current)
    gsap.killTweensOf(meshRef.current)

    gsap.to(ref.current, {
      backgroundOpacity: 1,
      duration: 0.3,
      onUpdate: () => invalidate()
    })

    if (isShown) {
      gsap.to(ref.current, {
        progress: 1,
        duration: 0.65,
        delay: 0.1,
        ease: 'cubic.out',
        onUpdate: () => invalidate()
      })

      gsap.set(meshRef.current, { opacity: 0 })

      gsap.to(meshRef.current, {
        opacity: 1,
        duration: 0.65,
        delay: 0.3,
        ease: 'cubic.out'
      })

      gsap.set(scaleRef.current.scale, {
        x: 1.5,
        y: 1.5,
        z: 1.5
      })

      gsap.to(scaleRef.current.scale, {
        x: 1,
        y: 1,
        z: 1,
        duration: 0.65,
        delay: 0.3,
        ease: 'back.out(1)'
      })
    } else {
      gsap.to(ref.current, {
        progress: 0,
        duration: 1,
        ease: 'cubic.in',
        onUpdate: () => invalidate()
      })

      gsap.to(meshRef.current, {
        opacity: 0,
        duration: 0.5,
        ease: 'cubic.out',
        onUpdate: () => invalidate()
      })

      gsap.to(scaleRef.current.scale, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.3,
        ease: 'back.in(0.5)',
        onUpdate: () => invalidate()
      })
    }

    return () => {
      gsap.killTweensOf(ref.current)
      gsap.killTweensOf(meshRef.current)
      gsap.killTweensOf(scaleRef.current)
    }
  }, [isShown])

  useEffect(() => {
    gsap.to(ref.current, {
      backgroundOpacity: 1,
      duration: 0.3,
      delay: 0.01,
      onUpdate: () => invalidate()
    })
  }, [])

  const scale = useMemo(() => {
    const ratioWidth = viewport.width / size.width
    const cScale = ratioWidth * 50

    return cScale
  }, [size, viewport])

  return (
    <>
      <ScreenQuad scale={100} depthWrite={false} position={[0, 0, -10]}>
        <sceneBackgroundMaterial
          ref={ref}
          key={SceneBackgroundMaterial.key}
          depthWrite={false}
          backgroundOpacity={1}
          colorTop={'#5f767b'}
          colorBottom={'#000'}
          progress={1.0}
          size={40}
          resolution={resolution}
          density={density}
          transparent
        />
      </ScreenQuad>
      <group ref={scaleRef} scale={0}>
        <mesh geometry={ShapeGeometry} scale={scale}>
          <meshBasicMaterial
            ref={meshRef}
            color="black"
            side={BackSide}
            transparent
            opacity={0}
          />
        </mesh>
      </group>
    </>
  )
}

Scene.propTypes = {
  isActive: PropTypes.bool,
  isWorker: PropTypes.bool
}
