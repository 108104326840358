import SimpleComponent from './SimpleComponent'

/**
 * The `BasePointer` SimpleComponent is the base implementation
 * for the `OnPointer` utility class. It can be used independently
 * of `OnPointer` for environments that are solely to relay information
 * (such as web workers), via the `OnVirtualPointer` class using the
 * `trigger`... methods.
 */
export default class BasePointer extends SimpleComponent {
  /** @type {object} */
  state = {
    cursorType: 'default',
    deltaY: 0,
    fingers: 1,
    isClickEnabled: true,
    isEntered: false,
    isPressed: false,
    lastTouchStart: null,
    pinchGestureCenter: { x: 0, y: 0 },
    pinchGestureScaleOffset: 0,
    spinY: 0,
    x: 0,
    y: 0
  }

  /** @type {object} */
  callbacks = {
    cursorType: new Set(),
    doubleTap: new Set(),
    enter: new Set(),
    leave: new Set(),
    move: new Set(),
    pinchGesture: new Set(),
    press: new Set(),
    click: new Set(),
    release: new Set(),
    wheel: new Set()
  }

  /** @type {Function} */
  triggerEnter = this.handleEnter

  /** @type {Function} */
  triggerLeave = this.handleLeave

  /** @type {Function} */
  triggerPress = this.handlePress

  /** @type {Function} */
  triggerClick = this.handleClick

  /** @type {Function} */
  triggerDoubleTap = this.handleDoubleTap

  /** @type {Function} */
  triggerMove = this.handleMove

  /** @type {Function} */
  triggerPinchGesture = this.handlePinchGesture

  /** @type {Function} */
  triggerRelease = this.handleRelease

  /** @type {Function} */
  triggerWheel = this.handleWheel

  /**
   * Execute callback position - the standard callback that provides
   * the x, y parameters of the pointer position
   * @param {Function} callback - the callback to execute
   * @returns {any} - callback result (unused)
   * @protected
   */
  executeCallbackPosition = callback => callback(this.state.x, this.state.y)

  /**
   * Execute callback for cursor type - sends the current cursor type
   * @param {Function} callback - the callback to execute
   * @returns {any} - callback result (unused)
   * @protected
   */
  executeCallbackCursorType = callback => callback(this.state.cursorType)

  /**
   * Execute callback for wheel events. Provides the deltaY and spinY
   * parameters
   * @param {Function} callback - the callback to execute
   * @returns {any} - callback result (unused)
   * @protected
   */
  executeCallbackWheel = callback =>
    callback(this.state.deltaY, this.state.spinY)

  /**
   * Execute callback for pinch gestures. Provides the scale offset
   * and pinch gesture center x and y
   * @param {Function} callback - the callback to execute
   * @returns {any} - callback result (unused)
   * @protected
   */
  executeCallbackPinchGesture = callback =>
    callback(
      this.state.pinchGestureScaleOffset,
      this.state.pinchGestureCenter.x,
      this.state.pinchGestureCenter.y
    )

  /**
   * Set whether "click" is enabled
   * @param {boolean} isClickEnabled - whether is enabled
   */
  setIsClickEnabled (isClickEnabled = true) {
    this.setState({ isClickEnabled })
  }

  /**
   * Set the cursor type
   * @param {string} cursorType - the cursor type
   * @public
   * @abstract
   */
  setCursorType (cursorType) {
    this.handleCursorTypeChange(cursorType)
  }

  /**
   * Sets the pointer position
   * @param {object} vector - the vector
   * @param {number} vector.x - the x position
   * @param {number} vector.y - the y position
   * @protected
   * @abstract
   */
  setPointerPosition ({ x, y }) {
    this.setState({ x, y })
  }

  /**
   * Sets the wheel data
   * @param {object} wheel - the wheel
   * @param {number} wheel.deltaY - the delta y
   * @param {number} wheel.spinY - the spin y
   * @protected
   * @abstract
   */
  setWheel ({ deltaY, spinY }) {
    this.setState({ spinY, deltaY })
  }

  /**
   * Sets the pinch gesture data
   * @param {object} pinch - the pinch
   * @param {object} pinch.pinchGestureCenter - the center vector (x, y)
   * @param {number} pinch.pinchGestureScaleOffset - the scale offset
   * @abstract
   * @protected
   */
  setPinchGesture ({ pinchGestureCenter, pinchGestureScaleOffset }) {
    this.setState({ pinchGestureCenter, pinchGestureScaleOffset })
  }

  /**
   * Handle the cursor type change
   * @param {string} cursorType - the cursor type
   * @protected
   */
  handleCursorTypeChange (cursorType) {
    this.setState({ cursorType })
    this.callbacks.cursorType.forEach(this.executeCallbackCursorType)
  }

  /**
   * Handles a generic position event (providing the pointer x, y)
   * @param {object} e - the event data object OR the x,y vector (virtual)
   * @param {Array} callbacks - the callback array
   * @protected
   */
  handleGenericPositionEvent (e, callbacks) {
    this.setPointerPosition(e)
    callbacks.forEach(this.executeCallbackPosition)
  }

  /**
   * Handle enter event
   * @param {object} e - the event data
   * @abstract
   * @protected
   */
  handleEnter = e => {
    this.setState({ isEntered: true })
    this.handleGenericPositionEvent(e, this.callbacks.enter)
  }

  /**
   * Handle leave event
   * @param {object} e - the event data
   * @abstract
   * @protected
   */
  handleLeave = e => {
    this.setState({ isEntered: false })
    this.handleGenericPositionEvent(e, this.callbacks.leave)
  }

  /**
   * Handle press event
   * @param {object} e - the event data
   * @abstract
   * @protected
   */
  handlePress = e => {
    this.setState({ isPressed: true })
    this.handleGenericPositionEvent(e, this.callbacks.press)
  }

  /**
   * Handle press event
   * @param {object} e - the event data
   * @abstract
   * @protected
   */
  handleClick = e => {
    if (!this.state.isClickEnabled) {
      // Defensive
      return
    }

    this.handleGenericPositionEvent(e, this.callbacks.click)
  }

  /**
   * Handle double tap event
   * @param {object} e - the event data
   * @abstract
   * @protected
   */
  handleDoubleTap = e => {
    this.callbacks.doubleTap.forEach(this.executeCallbackPosition)
  }

  /**
   * Handle move event
   * @param {object} e - the event data
   * @abstract
   * @protected
   */
  handleMove = e => {
    this.handleGenericPositionEvent(e, this.callbacks.move)
  }

  /**
   * Handle move when pressed
   * @param {object} e - the event data
   * @abstract
   * @protected
   */
  handleMoveWhenPressed = e => {
    this.handleGenericPositionEvent(e, this.callbacks.moveWhenPressed)
  }

  /**
   * Handle pinch gesture event
   * @param {object} e - the event data
   * @abstract
   * @protected
   */
  handlePinchGesture = e => {
    this.setPinchGesture(e)
    this.callbacks.pinchGesture.forEach(this.executeCallbackPinchGesture)
  }

  /**
   * Handle release event
   * @param {object} e - the event data
   * @abstract
   * @protected
   */
  handleRelease = e => {
    this.setState({ isPressed: false })
    this.handleGenericPositionEvent(e, this.callbacks.release)
  }

  /**
   * Handle wheel event
   * @param {object} e - the event data
   * @abstract
   * @protected
   */
  handleWheel = e => {
    this.setWheel(e)
    this.callbacks.wheel.forEach(this.executeCallbackWheel)
  }

  /**
   * On cursor change.
   * @param {Function} callback - the callback to register
   * @public
   */
  onCursorTypeChange (callback) {
    this.callbacks.cursorType.add(callback)
  }

  /**
   * Off cursor change.
   * @param {Function} callback - the callback to register
   * @public
   */
  offCursorTypeChange (callback) {
    this.callbacks.cursorTypeType.delete(callback)
  }

  /**
   * On enter
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  onEnter (callback) {
    this.callbacks.enter.add(callback)
  }

  /**
   * Off enter
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  offEnter (callback) {
    this.callbacks.enter.delete(callback)
  }

  /**
   * On leave
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  onLeave (callback) {
    this.callbacks.leave.add(callback)
  }

  /**
   * Off leave
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  offLeave (callback) {
    this.callbacks.leave.delete(callback)
  }

  /**
   * On press
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  onPress (callback) {
    this.callbacks.press.add(callback)
  }

  /**
   * Off press
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  offPress (callback) {
    this.callbacks.press.delete(callback)
  }

  /**
   * On click
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  onClick (callback) {
    this.callbacks.click.add(callback)
  }

  /**
   * Off click
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  offClick (callback) {
    this.callbacks.click.delete(callback)
  }

  /**
   * On double tap
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  onDoubleTap (callback) {
    this.callbacks.doubleTap.add(callback)
  }

  /**
   * Off double tap
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  offDoubleTap (callback) {
    this.callbacks.doubleTap.delete(callback)
  }

  /**
   * On move
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  onMove (callback) {
    this.callbacks.move.add(callback)
  }

  /**
   * Off move
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  offMove (callback) {
    this.callbacks.move.delete(callback)
  }

  /**
   * On pinch gesture
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  onPinchGesture (callback) {
    this.callbacks.pinchGesture.add(callback)
  }

  /**
   * Off pinch gesture
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  offPinchGesture (callback) {
    this.callbacks.pinchGesture.delete(callback)
  }

  /**
   * On release
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  onRelease (callback) {
    this.callbacks.release.add(callback)
  }

  /**
   * Off release
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  offRelease (callback) {
    this.callbacks.release.delete(callback)
  }

  /**
   * On wheel
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  onWheel (callback) {
    this.callbacks.wheel.add(callback)
  }

  /**
   * Off wheel
   * @param {Function} callback - the callback to register
   * @abstract
   * @public
   */
  offWheel (callback) {
    this.callbacks.wheel.delete(callback)
  }

  /**
   * Destroy
   * @public
   */
  destroy () {
    this.callbacks.cursorType.forEach(callback =>
      this.offCursorTypeChange(callback)
    )

    this.callbacks.doubleTap.forEach(callback => this.offDoubleTap(callback))
    this.callbacks.enter.forEach(callback => this.offEnter(callback))
    this.callbacks.leave.forEach(callback => this.offLeave(callback))
    this.callbacks.move.forEach(callback => this.offMove(callback))

    this.callbacks.pinchGesture.forEach(callback =>
      this.offPinchGesture(callback)
    )

    this.callbacks.press.forEach(callback => this.offPress(callback))
    this.callbacks.release.forEach(callback => this.offRelease(callback))
    this.callbacks.wheel.forEach(callback => this.offWheel(callback))
  }
}

/** @type {object} */
BasePointer.defaultProps = {
  preventDefault: true
}
