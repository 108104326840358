/**
 * Get the document (page) title. Used by the `Routes` to
 * take the input data and return the composed page title
 * @param {object} props - the input props
 * @param {object} props.appData - the app data json object
 * @param {string} props.route - the current route
 * @param {string} props.separator - the separator to use
 * @returns {string} the document title
 */
const getDocumentTitle = ({ appData, route, separator = '|' }) => {
  const routeData = appData?.routes?.[route.key]
  const siteTitle = appData?.metadata?.siteTitle || 'Metropolis'
  const tagline = appData?.metadata?.siteTagLine || ''

  if (!route || !route.type || !appData || !routeData) {
    // Defensive
    return `${siteTitle}${tagline}`
  }

  if (!routeData.documentTitle) {
    // Defensive when no title (all non-view routes should have titles though)
    return `${siteTitle}${tagline}`
  }

  // Is a regular route, name it
  return `${siteTitle} ${separator} ${routeData.documentTitle}`
}

/**
 * Get a custom documentTitle
 * @param {object} props - the input props
 * @param {object} props.appData - the app data json object
 * @param {string|Array} props.documentTitle - the document title
 * @param {string} props.separator - the separator
 * @returns {string} the document title
 */
export const getCustomDocumentTitle = ({
  appData,
  documentTitle,
  separator = ' | '
}) => {
  const siteTitle = appData?.metadata?.siteTitle || 'Metropolis'
  const outputTitle = Array.isArray(documentTitle)
    ? documentTitle.join(separator)
    : documentTitle

  return `${siteTitle} ${separator} ${outputTitle}`
}

export default getDocumentTitle
