/**
 * Quick className generation, accepts falsy and strips
 * @param {...any} args - the arguments
 * @returns {string} the result
 */
function classNames (...args) {
  const flattenedArgs = args.flatMap(arg =>
    Array.isArray(arg) ? arg : [arg]
  )

  return flattenedArgs.filter(Boolean).join(' ').trim()
}

export default classNames
