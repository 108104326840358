import { useRootContext } from '../RootProvider/RootProvider'

/**
 * The `useAppData` is a quick hook for
 * accessing the common application data. Usage
 * requires the `RootProvider` context
 * @returns {object} the application data
 */
function useAppData () {
  const { appData } = useRootContext()

  return appData
}

// Export
export default useAppData
