import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntersectionContext } from '../Context/Context'

/**
 * Create an IntersectionObserver instance for a ref
 * @param {RefObject} ref - Ref for the React element (result of useRef call)
 * @param {boolean} triggerOnce - Only trigger the observer one time
 * @param {object} options - Options for IntersectionObserver instance (also allows `triggerOnce` options)
 * @param {boolean} ignoreAbove - Whether to ignore when the element is above the viewport (one way)
 * @param {boolean} useBatch - whether to batch intersection events
 * @returns {boolean} Whether that DOM node is intersecting the observer
 */
export default function useIntersectionObserver (
  ref,
  triggerOnce,
  options,
  ignoreAbove = false,
  useBatch = false
) {
  const { observer: sharedObserver } = useIntersectionContext()
  const [isIntersecting, setIsIntersecting] = useState(false)
  const [intersectionDelay, setIntersectionDelay] = useState(0)
  const didIntersect = useRef(false)
  const isMounted = useRef()
  const lastRef = useRef()

  /**
   * Memo: The observer instance
   */
  const observerInstance = useMemo(() => {
    if (sharedObserver) {
      return sharedObserver
    }

    return new IntersectionObserver(entries => {
      const isIntersecting = entries.some(entry => entry.isIntersecting)

      // Send the last entry as its possible for an intersection event
      // to provide multiple entries even if there was only one element
      // being observed - the last of which being the last entry event
      // and so, being the latest info
      handleIntersect(isIntersecting, entries[entries.length - 1])
    }, options)
  }, [])

  /**
   * Handle intersection
   */
  const handleIntersect = useCallback((isIntersecting, entry, delay) => {
    if (!isMounted.current) {
      return
    }

    if (!(ignoreAbove && !isIntersecting && entry.boundingClientRect.y < 0)) {
      // When using the `ignoreAbove`
      setIsIntersecting(isIntersecting)
      setIntersectionDelay(delay || 0)
    }

    if (isIntersecting && triggerOnce) {
      observerInstance.unobserve(ref.current)
    }
  }, [])

  /**
   * Effect: On mount/unmount
   */
  useEffect(() => {
    isMounted.current = true

    const el = ref.current

    if (ref?.current) {
      if (lastRef.current) {
        observerInstance.unobserve(lastRef.current)
      }

      ref.current.handleIntersect = handleIntersect
      ref.current.useBatch = useBatch
      observerInstance.observe(ref.current)
      lastRef.current = ref.current
    }

    return () => {
      isMounted.current = false

      if (el) {
        observerInstance.unobserve(el)

        if (!sharedObserver) {
          observerInstance.disconnect()
        }
      }
    }
  }, [])

  /**
   * Memo: the context
   */
  const ctx = useMemo(() => {
    if (isIntersecting) {
      didIntersect.current = true
    }

    return {
      isIntersecting,
      intersectionDelay,
      didIntersect: didIntersect.current
    }
  }, [isIntersecting, intersectionDelay])

  // return context
  return ctx
}
