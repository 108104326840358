import { throttle as throttleLib } from 'throttle-debounce'
/**
 * Throttle
 * @param {Function} callback - the callback
 * @param {number} waitTime - the wait time
 * @returns {Function} the function
 */
function throttle (callback, waitTime = 0) {
  return throttleLib(waitTime, callback)
}

export default throttle
