import gsap from 'gsap'
import PropTypes from 'prop-types'
import { memo, useLayoutEffect, useMemo, useRef } from 'react'
import classNames from '@/lib/util/classNames'
import { useOrganicRevealContext } from '../OrganicReveal'
import styles from './Block.module.scss'

const revealMethods = {
  stagger: (index, total) => {
    const maxDelay = Math.min(0.5, 0.1 + total * 0.05)

    return (index / (total - 1)) * maxDelay || 0
  },
  jumble: (index, total) => {
    const maxDelay = Math.min(0.25, 0.1 + total * 0.05)
    const randomDelay = Math.random() * maxDelay

    return Math.min(randomDelay, maxDelay) || 0
  }
}

/**
 * The `Block
 * @param {object} props - the component props
 * @returns {React.ReactElement} - the element
 */
function Block (props) {
  const { children, index = 0 } = props
  const {
    delayShow,
    isActive,
    isText,
    revealMethod,
    rotation: rotate,
    total
  } = useOrganicRevealContext()
  const ref = useRef()
  const classNameOutput = useMemo(
    () => classNames(styles.container, isText && styles.text),
    [isText]
  )

  useLayoutEffect(() => {
    const el = ref?.current

    if (!el) {
      return
    }

    gsap.killTweensOf(el)
    gsap.set(el, { y: '200%', rotate })

    if (isActive) {
      const delayMethod = revealMethods[revealMethod]
        ? revealMethods[revealMethod]
        : revealMethods.stagger

      gsap.to(el, {
        y: '0%',
        rotate: '0deg',
        duration: 1.2,
        delay: delayShow + delayMethod(index, total),
        ease: 'expo.out'
      })
    }

    return () => {
      if (!el) {
        return
      }

      gsap.killTweensOf(el)
    }
  }, [isActive, index, rotate, revealMethod, total])

  return (
    <div className={classNameOutput}>
      <div ref={ref} className={styles.child}>
        {children}
      </div>
    </div>
  )
}

Block.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number
}

export default memo(Block)
