import { Suspense } from 'react'
import Routes from '@/containers/Routes/Routes'
import styles from './App.module.scss'
import Header from './components/interface/Header/Header'
import Loader from './components/interface/Loader/Loader'
import CanvasContainer from './gl/CanvasContainer/CanvasContainer'

/**
 * The App
 * @returns {React.ReactElement} the element
 */
export default function App () {
  return (
    <div className={styles.container}>
      <main aria-label="Main Content">
        <CanvasContainer>
          <Suspense fallback={<div className={styles.routes} />}>
            <Routes />
          </Suspense>
          <Loader.GLSuspense />
        </CanvasContainer>
        <Header />
        <Loader.Display />
      </main>
    </div>
  )
}
