import BaseResize from './BaseResize'

/**
 * The `OnResize` utility can be used to unify `resize`
 * event handling. It comes bundled with a series of
 * useful behaviors such as batching and debouncing,
 * expanding the capability of the standard resize handler.
 *
 * This utility should be used for ALL resize event handling
 */
class OnResize extends BaseResize {
  /** @type {number} */
  width = document.documentElement.clientWidth

  /** @type {number} */
  height = document.documentElement.clientHeight

  /** @type {number} */
  max = Math.max(
    document.documentElement.clientWidth,
    document.documentElement.clientHeight
  )

  /** @type {number} */
  min = Math.min(
    document.documentElement.clientHeight,
    document.documentElement.clientWidth
  )

  /** @type {boolean} */
  isResizing = false

  /** @type {number} */
  rAFID = null

  /** @type {boolean} */
  isListening = false

  /**
   * Handle the resize - can be called publicly via
   * the `trigger` method
   * @override
   * @protected
   */
  handleResize = () => {
    this.isResizing = true
    this.width = document.documentElement.clientWidth
    this.height = document.documentElement.clientHeight

    this.setMinMax(this.width, this.height)
    this.executeBatches()
    cancelAnimationFrame(this.rAFID)
    this.rAFID = requestAnimationFrame(this.executeBatches)
  }

  /**
   * On - the public method to add a resize listener
   * @param {Function} callback - the callback
   * @param {number} waitTime - the debounce wait time
   * @override
   * @public
   */
  on (callback, waitTime = BaseResize.DEBOUNCE_TIMEOUT) {
    this.add(callback, waitTime)

    if (this.isListening) {
      // Already listening, don't add
      return
    }

    window.addEventListener('resize', this.handleResize)

    this.isListening = true
  }

  /**
   * Off - the public method to remove a resize listener
   * @param {Function} callback - the callback
   * @override
   * @public
   */
  off (callback) {
    this.remove(callback)

    if (!this.isListening || this.debounceBatches.size) {
      // Not currently listening or still has debounce batches
      return
    }

    window.removeEventListener('resize', this.handleResize)

    this.isListening = false
  }
}

// Export instance
export default new OnResize()
