import gsap from 'gsap'
import PropTypes from 'prop-types'
import { memo, useLayoutEffect, useMemo, useRef } from 'react'
import classNames from '@/lib/util/classNames'
import { useOrganicRevealContext } from '../OrganicReveal'
import styles from './Group.module.scss'

const shiftMethods = {
  stagger: (index, total) => {
    const maxDelay = Math.min(0.5, 0.1 + total * 0.05)

    return maxDelay + (index / (total - 1)) * maxDelay || 0
  },

  jumble: (index, total) => {
    const maxDelay = Math.min(0.45, 0.1 + total * 0.05)

    return maxDelay + (index / (total - 1)) * maxDelay || 0
  }
}

/**
 * The `Group
 * @param {object} props - the component props
 * @returns {React.ReactElement} - the element
 */
function Group (props) {
  const { children, index } = props
  const {
    delayShow,
    groupShift = null,
    invertGroupShift,
    isActive,
    isText,
    revealMethod,
    total
  } = useOrganicRevealContext()
  const wasActive = useRef(isActive)
  const ref = useRef()
  const classNameOutput = useMemo(
    () => classNames(styles.container, isText && styles.text),
    [isText]
  )

  useLayoutEffect(() => {
    const el = ref?.current

    if (!groupShift || isActive === wasActive.current || !el) {
      return
    }

    wasActive.current = isActive
    gsap.killTweensOf(el)
    gsap.set(el, { x: groupShift?.x, y: groupShift?.y })

    if (isActive) {
      const delayMethod = shiftMethods[revealMethod]
        ? shiftMethods[revealMethod]
        : shiftMethods.stagger

      gsap.to(el, {
        x: 0,
        y: 0,
        duration: 0.85,
        delay:
          delayShow +
          delayMethod(invertGroupShift ? total - 1 - index : index, total),
        ease: 'cubic.inOut(0.5)'
      })
    }

    return () => {
      if (!el) {
        return
      }

      gsap.killTweensOf(el)
    }
  }, [isActive, groupShift, invertGroupShift, index, revealMethod, total])

  return (
    <div ref={ref} className={classNameOutput}>
      {children}
    </div>
  )
}

Group.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number
}

export default memo(Group)
