import Display from './Display/Display'
import GLSuspense from './GLSuspense/GLSuspense'
import Provider from './Provider/Provider'
import Suspense from './Suspense/Suspense'

const Loader = {
  Provider,
  Display,
  Suspense,
  GLSuspense
}

export default Loader
