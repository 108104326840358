/**
 * The `SimpleComponent` mimics (in a simple form) the standard
 * react `Component`. It is used for non-react components that
 * benefit from having some stateful behaviors, but are not applicable
 * for use with `React`
 */
export default class SimpleComponent {
  /** @type {object} */
  static defaultProps = {}

  /** @type {boolean} */
  _isPropsChanging = false

  /**
   * @class
   * @param {object} props - the configuration props
   */
  constructor (props) {
    /** @type {object} */
    this.props = { ...this.constructor.defaultProps, ...props }

    /** @type {object} */
    this.state = { ...this.constructor.defaultState }
  }

  /**
   * Component will receive props
   * @param {object} props - the props object to apply
   * @public
   */
  setProps (props) {
    const lastProps = this.props
    const lastState = this.state
    const nextProps = {
      ...lastProps,
      ...props
    }

    this._isPropsChanging = true
    this.componentWillReceiveProps(nextProps)
    this._isPropsChanging = false
    this.props = nextProps

    this.componentDidUpdate(lastProps, lastState)
  }

  setState (state) {
    const lastState = this.state
    const nextState = {
      ...lastState,
      ...state
    }

    this.state = nextState

    if (this._isPropsChanging) {
      // Batch
      return
    }

    this.componentDidUpdate(this.props, lastState)
  }

  /**
   * Component will receive props
   * @abstract
   * @private
   */
  componentWillReceiveProps () {}

  /**
   * Component did update
   * @abstract
   * @private
   */
  componentDidUpdate () {}

  /**
   * Destroy
   * @abstract
   * @public
   */
  destroy () {}
}
