import data from 'app.data'
import PropTypes from 'prop-types'
import { createContext, useContext, useMemo } from 'react'
import tunnel from 'tunnel-rat'
import useInterface from './hooks/useInterface'
import useRoutes from './hooks/useRoutes/useRoutes'

export const RootContext = createContext({})

// Use root context utility
export const useRootContext = () => {
  const context = useContext(RootContext)

  return context
}

/**
 * The `RootProvider`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
export default function RootProvider (props) {
  const { children } = props
  const appData = useMemo(() => data, [])
  const canvasTunnel = tunnel()
  const { isRouteTransitioning, route, setDocumentTitle } = useRoutes(data)
  const { isBurgerMenuOpen, setIsBurgerMenuOpen } = useInterface(
    appData,
    route,
    isRouteTransitioning
  )

  const ctx = useMemo(
    () => ({
      isBurgerMenuOpen,
      setIsBurgerMenuOpen,
      appData,
      route,
      setDocumentTitle,
      isRouteTransitioning,
      canvasTunnel
    }),
    [
      isBurgerMenuOpen,
      setIsBurgerMenuOpen,
      appData,
      route,
      setDocumentTitle,
      isRouteTransitioning
    ]
  )

  return <RootContext.Provider value={ctx}>{children}</RootContext.Provider>
}

/** @type {object} */
RootProvider.propTypes = {
  children: PropTypes.node
}
