const mapTouches = touch => ({ x: touch.pageX, y: touch.pageY })
const mapX = ({ x }) => x
const mapY = ({ y }) => y
const sum = (a, b) => a + b
const getDistance = (p1 = { x: 0, y: 0 }, p2 = { x: 0, y: 0 }) =>
  Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))

export const getTouches = touches => {
  if (!touches) {
    return []
  }

  return Array.from(touches).map(mapTouches)
}

export const getTouchCenter = vectors => {
  const len = vectors.length

  return {
    x: vectors.map(mapX).reduce(sum) / len,
    y: vectors.map(mapY).reduce(sum) / len
  }
}

export const getPinchGestureScaleOffset = (startTouches, endTouches) => {
  if (startTouches.length < 1 || endTouches.length < 1) {
    return 0
  }

  const distanceStart = getDistance(startTouches[0], startTouches[1])
  const distanceEnd = getDistance(endTouches[0], endTouches[1])

  return distanceEnd / distanceStart - 1
}
