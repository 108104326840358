import DebounceBatch from './instance/DebounceBatch'

/**
 * The `BaseResize` handles the core resize methods
 * that can be shared between the standard and virtual
 * on resize handling
 */
export default class BaseResize {
  /** @type {number} */
  static DEBOUNCE_TIMEOUT = 150

  /** @type {Map} */
  debounceBatches = new Map()

  /** @type {Map} */
  callbacks = new Map()

  /** @type {number} */
  width = 0

  /** @type {number} */
  height = 0

  /** @type {number} */
  max = 0

  /** @type {number} */
  min = 0

  /** @type {boolean} */
  isResizing = false

  /** @type {Function} */
  trigger = this.handlResize

  /**
   * Execute a batch
   * @param {object} batch - the batch to execute
   * @returns {null} empty
   * @private
   */
  executeBatch = batch => batch.execute()

  /**
   * Executes batches
   * @protected
   */
  executeBatches = () => {
    this.debounceBatches.forEach(this.executeBatch)
    this.isResizing = false
  }

  /**
   * Sets the minmax
   * @param {number} width - the width
   * @param {number} height - the height
   * @protected
   */
  setMinMax (width, height) {
    this.max = Math.max(width, height)
    this.min = Math.min(width, height)
  }

  /**
   * Handle the resize - can be called publicly via
   * the `trigger` method
   * @param {number} width - the width
   * @param {number} height - the height
   * @abstract
   * @protected
   */
  handleResize = (width, height) => {
    this.isResizing = true
    this.width = width
    this.height = height

    this.setMinMax(width, height)
    this.executeBatches()
  }

  /**
   * Add
   * @param {Function} callback - the callback
   * @param {number} waitTime - the debounce wait time
   * @protected
   */
  add (callback, waitTime = this.constructor.DEBOUNCE_TIMEOUT) {
    if (typeof callback !== 'function') {
      return
    }

    const matchedWait = this.callbacks.get(callback)
    const matchedDebounce = this.debounceBatches.get(waitTime)

    if (matchedWait !== undefined && matchedWait !== waitTime) {
      removeEventListener(callback)
    }

    this.callbacks.set(callback, waitTime)

    if (!matchedDebounce) {
      // Debounce group does not exist. Create it
      this.debounceBatches.set(
        waitTime,
        new DebounceBatch({ waitTime, callback })
      )

      return
    }

    matchedDebounce.add(callback)
  }

  /**
   * Remove
   * @param {Function} callback - the callback
   * @protected
   */
  remove (callback) {
    if (typeof callback !== 'function') {
      return
    }

    const matchedWait = this.callbacks.get(callback)

    if (matchedWait === undefined) {
      // Defensive when not found
      return
    }

    const size = this.debounceBatches.get(matchedWait).remove(callback)

    this.callbacks.delete(callback)

    if (size) {
      // Still has items in debounce batch
      return
    }

    this.debounceBatches.delete(matchedWait)
  }

  /**
   * On - the public method to add a resize listener
   * @param {Function} callback - the callback
   * @param {number} waitTime - the debounce wait time
   * @abstract
   * @public
   */
  on (callback, waitTime = this.constructor.DEBOUNCE_TIMEOUT) {
    this.add(callback, waitTime)
  }

  /**
   * Off - the public method to remove a resize listener
   * @param {Function} callback - the callback
   * @abstract
   * @public
   */
  off (callback) {
    this.remove(callback)
  }
}
