import { useRef } from 'react'

/**
 * Can be used in lieu of react's strictmode forcing
 * `useRef's` from being created twice on mount when
 * using them to create provider contexts for child
 * elements
 * @param {Function} cb - the callback
 */
function useComponentWillMount (cb) {
  const willMount = useRef(true)

  if (willMount.current) cb()

  willMount.current = false
}

export default useComponentWillMount
