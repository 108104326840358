import { Shape, ShapeGeometry } from 'three'
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js'

const shape1 = new Shape()

shape1.moveTo(-0.02, -0.24)
shape1.lineTo(-0.5, -0.12)
shape1.lineTo(-0.5, -0.01)
shape1.lineTo(-0.02, -0.14)

const shape2 = new Shape()

shape2.moveTo(0.02, -0.24)
shape2.lineTo(0.5, -0.12)
shape2.lineTo(0.5, -0.01)
shape2.lineTo(0.02, -0.14)

const shape3 = new Shape()

shape3.moveTo(-0.02, -0.08)
shape3.lineTo(-0.5, 0.03)
shape3.lineTo(-0.5, 0.14)
shape3.lineTo(-0.02, 0.01)

const shape4 = new Shape()

shape4.moveTo(0.02, -0.08)
shape4.lineTo(0.5, 0.03)
shape4.lineTo(0.5, 0.14)
shape4.lineTo(0.02, 0.01)

const shape5 = new Shape()

shape5.moveTo(-0.02, 0.07)
shape5.lineTo(-0.46, 0.2)
shape5.lineTo(-0.02, 0.24)

const shape6 = new Shape()

shape6.moveTo(0.02, 0.07)
shape6.lineTo(0.46, 0.2)
shape6.lineTo(0.02, 0.24)

const geometry1 = new ShapeGeometry(shape1)
const geometry2 = new ShapeGeometry(shape2)
const geometry3 = new ShapeGeometry(shape3)
const geometry4 = new ShapeGeometry(shape4)
const geometry5 = new ShapeGeometry(shape5)
const geometry6 = new ShapeGeometry(shape6)

const mergedGeometry = BufferGeometryUtils.mergeGeometries([
  geometry1,
  geometry2,
  geometry3,
  geometry4,
  geometry5,
  geometry6
])

mergedGeometry.scale(1, -1, 1)

export default mergedGeometry
