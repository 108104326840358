import { debounce as debounceLib } from 'throttle-debounce'

/**
 * Debounce
 * @param {Function} callback - the callback
 * @param {number} waitTime - the wait time
 * @returns {Function} the function
 */
function debounce (callback, waitTime = 0) {
  return debounceLib(waitTime, callback)
}

export default debounce
