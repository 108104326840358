export const SIZES = {
  'x-large': 'xl',
  'extra-large': 'xl',
  large: 'lg',
  medium: 'md',
  standard: 'std',
  small: 'sm',
  'x-small': 'xs',
  'extra-small': 'xs'
}

/**
 * Converts a size value into its matching key (used for breakpoints)
 * @param {string} size - the input size string
 * @returns {string} the converted size
 */
export const normalizeSizeString = size => SIZES[size] || size
