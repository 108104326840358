/**
 * Sort by position
 * @param {object} a - the first
 * @param {object} b - the second
 * @returns {number} the sort value
 */
export default function sortByPosition (a, b) {
  const distanceA = Math.sqrt(a.x * a.x + a.y * a.y)
  const distanceB = Math.sqrt(b.x * b.x + b.y * b.y)

  return distanceA - distanceB
}
