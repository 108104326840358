import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import useIntersectionObserver from '../hooks/useIntersectionObserver'
import styles from './FadeUp.module.scss'

/**
 * The `FadeUp`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
const FadeUp = forwardRef((props, forwardedRef) => {
  const { Tag, children, className, ...rest } = props
  const ref = useFallbackRef(forwardedRef)
  const { isIntersecting } = useIntersectionObserver(
    ref,
    false,
    null,
    true,
    true
  )
  const classNameOutput = useMemo(
    () =>
      classNames([
        className,
        styles.container,
        isIntersecting && styles.intersect
      ]),
    [className, isIntersecting]
  )

  return (
    <Tag ref={ref} className={classNameOutput} {...rest}>
      {children}
    </Tag>
  )
})

FadeUp.displayName = 'FadeUp'

/** @type {object} */
FadeUp.propTypes = {
  Tag: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string
}

/** @type {object} */
FadeUp.defaultProps = {
  Tag: 'div',
  children: null,
  className: ''
}

// Memoize
export default memo(FadeUp)
