import PropTypes from 'prop-types'
import {
  Fragment,
  createContext,
  forwardRef,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useLoader } from '@/components/interface/Loader/Provider/Provider'
import useIntersectionObserver from '@/lib/react/Intersection/hooks/useIntersectionObserver'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import { isTouch } from '@/lib/util/support'
import Block from './Block/Block'
import Group from './Group/Group'
import styles from './OrganicReveal.module.scss'

export const OrganicRevealContext = createContext({})

// Use root context utility
export const useOrganicRevealContext = () => {
  const context = useContext(OrganicRevealContext)

  return context
}

/**
 * The `OrganicReveal`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
const OrganicReveal = forwardRef((props, forwardedRef) => {
  const {
    Tag = 'div',
    active = null,
    children,
    className,
    delayShow = 0,
    fixTypography = true,
    groupShift = null,
    invertGroupShift = false,
    letters: splitLetters = false,
    revealMethod = 'stagger',
    rotation = '10deg'
  } = props
  const { isReady } = useLoader()
  const [isLoaded, setIsLoaded] = useState(false)
  const ref = useFallbackRef(forwardedRef)
  const intersectionRef = useRef()
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )
  const isText = useMemo(
    () => typeof children === 'string' && fixTypography,
    [children, fixTypography]
  )
  const groups = useMemo(
    () => (isText ? children.split(' ') : [children]),
    [children, isText]
  )

  const { intersectionDelay, isIntersecting } = useIntersectionObserver(
    intersectionRef,
    false,
    null,
    false,
    true
  )

  const [total, setBlockCount] = useState(0)
  const [groupTotal, setGroupCount] = useState(0)

  const output = useMemo(() => {
    const space = ' '
    let groupIndex = 0
    let blockIndex = 0

    const blocks = groups.map((group, i) => (
      <Fragment key={i}>
        <Group key={i} index={groupIndex++}>
          {splitLetters && !isTouch && isText
            ? (
                group.split('').map((char, j) => (
              <Block key={j} index={blockIndex++}>
                {char}
              </Block>
                ))
              )
            : (
            <Block key={i} index={blockIndex++}>
              {group}
            </Block>
              )}
        </Group>
        {i < groups.length - 1 && space}
      </Fragment>
    ))

    setGroupCount(groupIndex)
    setBlockCount(blockIndex)

    return blocks
  }, [groups, splitLetters, isText])

  useEffect(() => {
    if (isReady) {
      setIsLoaded(true)
    }
  }, [isReady])

  const ctx = useMemo(
    () => ({
      isActive: isLoaded ? (active !== null ? active : isIntersecting) : false,
      total,
      delayShow: delayShow + (active !== null ? 0 : intersectionDelay || 0),
      rotation,
      revealMethod,
      groupTotal,
      groupShift,
      invertGroupShift,
      isText
    }),
    [
      isLoaded,
      active,
      isIntersecting,
      total,
      delayShow,
      rotation,
      revealMethod,
      groupTotal,
      groupShift,
      invertGroupShift,
      isText,
      intersectionDelay,
      fixTypography
    ]
  )

  return (
    <OrganicRevealContext.Provider value={ctx}>
      <Tag ref={ref} className={classNameOutput}>
        <div ref={intersectionRef} className={styles.intersection} />
        {output}
      </Tag>
    </OrganicRevealContext.Provider>
  )
})

OrganicReveal.displayName = 'OrganicReveal'

/** @type {object} */
OrganicReveal.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  Tag: PropTypes.elementType,
  children: PropTypes.node,
  letters: PropTypes.bool,
  delayShow: PropTypes.number,
  rotation: PropTypes.string,
  revealMethod: PropTypes.string,
  groupShift: PropTypes.shape(),
  invertGroupShift: PropTypes.bool,
  fixTypography: PropTypes.bool
}

// Memoize
export default memo(OrganicReveal)
