import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'

export const LoaderContext = createContext()

export const useLoader = () => {
  const context = useContext(LoaderContext)

  return context
}

/**
 * The `Loader` Provider
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
export default function Provider (props) {
  const { children } = props
  const { isRouteTransitioning } = useRootContext()
  const loadState = useMemo(
    () => ({
      targetProgress: 0,
      loading: true,
      artificialProgress: 0,
      progress: 0
    }),
    []
  )
  const [loaders, setLoaders] = useState(new Map())
  const [isReady, setIsReady] = useState(false)
  const loadersRef = useRef(loaders)
  const [countLoaders, setCountLoaders] = useState(0)
  const [hasLoaders, setHasLoaders] = useState(false)
  const isLoaderActive = useDelayedActiveState(!isReady, 0.02, 0)
  const isLoaderRendered = useDelayedActiveState(!isReady, 0, 1)
  const frame = useRef()
  const incrementTimeout = useRef()
  const isLoaderStarted = useRef(false)

  const addLoader = useCallback(loaderId => {
    setLoaders(() => {
      loadersRef.current.set(loaderId, { progress: 0 })

      setCountLoaders(loadersRef.current.size)
      setHasLoaders(true)

      return loadersRef.current
    })
  }, [])

  const removeLoader = useCallback(loaderId => {
    setLoaders(() => {
      loadersRef.current.delete(loaderId)

      setCountLoaders(loadersRef.current.size)
      setHasLoaders(!!loadersRef.current.size)

      return loadersRef.current
    })
  }, [])

  const updateLoaderProgress = useCallback(
    (loaderId, progress) => {
      const match = loadersRef.current.get(loaderId)

      if (match) {
        match.progress = progress / 100
      }

      if (!loadersRef.current.size) {
        return
      }

      const highestProgress = [...loadersRef.current].reduce(
        (maxProgress, [, { progress }]) => Math.max(maxProgress, progress),
        -Infinity
      )

      loadState.targetProgress = highestProgress
    },
    [loaders]
  )

  useEffect(() => {
    if (hasLoaders) {
      startLoader()
      loadState.loading = true
    } else {
      loadState.loading = false
    }
  }, [hasLoaders])

  const startLoader = useCallback(() => {
    loadState.progress = 0
    loadState.artificialProgress = 0

    if (isLoaderStarted.current) {
      return
    }

    isLoaderStarted.current = true

    cancelAnimationFrame(frame.current)
    clearTimeout(incrementTimeout.current)

    let increment = 0

    incrementTimeout.current = setTimeout(() => {
      increment = !loadersRef.current.size ? 0.05 : 0.015
    }, 750)

    const tick = () => {
      const targetProgress = Math.max(
        0.1,
        loadState.progress,
        !loadState.loading ? 1 : loadState.targetProgress
      )

      loadState.artificialProgress += Math.random() * 0.001

      if (targetProgress >= loadState.progress) {
        loadState.progress += increment

        loadState.progress = Math.min(1, loadState.progress, targetProgress)
      } else {
        loadState.progress -= 0.01

        loadState.progress = Math.max(0, loadState.progress, targetProgress)
      }

      if (loadState.progress < 1) {
        frame.current = requestAnimationFrame(tick)
      } else {
        isLoaderStarted.current = false
        setIsReady(true)
      }
    }

    setIsReady(false)
    frame.current = requestAnimationFrame(tick)

    return () => {
      cancelAnimationFrame(frame.current)
      clearTimeout(incrementTimeout.current)
    }
  }, [])

  useEffect(() => {
    if (isRouteTransitioning) {
      startLoader()
    }
  }, [isRouteTransitioning])

  useEffect(() => {
    startLoader()
  }, [])

  const ctx = useMemo(
    () => ({
      addLoader,
      removeLoader,
      isReady,
      isLoaderActive,
      hasLoaders,
      countLoaders,
      updateLoaderProgress,
      loadState,
      setIsReady,
      isLoaderRendered,
      startLoader
    }),
    [
      isReady,
      isLoaderActive,
      countLoaders,
      updateLoaderProgress,
      hasLoaders,
      isLoaderRendered,
      startLoader
    ]
  )

  return (
    <LoaderContext.Provider value={ctx}>{children}</LoaderContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node
}
